import DefaultLayout from "../../layout/DefaultLayout"
import Hero from "./components/Hero"
import SectionCardMachine from "./components/SectionCardMachine"
import SectionLoans from "./components/SectionLoans"
import SectionCard from "./components/SectionCard"
import SectionAccount from "./components/SectionAccount"
import ContentSection from "./components/ContentSection"
import SectionAbout from "./components/SectionAbout"

const Main = ({ pageContext, location }) => {
  return (
    <DefaultLayout pageContext={pageContext} location={location} >
      <>
        <Hero />
        <SectionCardMachine />
        <SectionLoans />
        <SectionCard />
        <SectionAccount />
        <ContentSection />
        <SectionAbout />
      </>
    </DefaultLayout>
  )
}

export default Main
