import Text from "@mkt/ui/components/Azulis/Text"
import Arrow from "@mkt/ui/icons/Azulis/Arrow"
import Img from "@mkt/ui/components/common/Img"
import { dataHero } from "../../data"
import useHero from "./hook"
import * as S from "./styles"

const Hero = () => {
  const { title, subtitle, image } = dataHero
  const { scrollDown } = useHero()

  return (
    <div css={S.HeroWrapper}>
      <figure css={S.HeroImageContainer}>
        <Img src={image.path} alt={image.alt} />
      </figure>
      <div css={S.HeroContainer}>
        <section css={S.HeroContentInfo}>
          <Text
            as="h1"
            variant={["HeadingOne", S.TitleWhite, S.TextFontWeightSemiBold]}
          >
            {title}
          </Text>
          <div>
            <Text
              as="p"
              variant={["Text", "Text16", S.TitleWhite, S.SubtitleContainer]}
            >
              {subtitle}
            </Text>
            <button css={S.ButtonScroll} onClick={scrollDown} aria-label="Botão para navegar até a seção das maquininhas.">
              <Arrow />
            </button>
          </div>
        </section>
        <div css={S.HeroDash}></div>
      </div>
    </div>
  )
}

export default Hero
