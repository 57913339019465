import Button from "@mkt/ui/components/Azulis/Button"
import Img from "@mkt/ui/components/common/Img"
import { dataCardMachine } from "../../data"
import useLanding from "../../hooks"
import ContentInformation from "../ContentInformation"
import * as S from "./styles"

const SectionCardMachine = () => {
  const { titleSection, image, content, button } = dataCardMachine
  const { handleRedirect } = useLanding({ button })

  return (
    <div css={S.CardMachineContainer}>
      <div css={S.CardMachineSectionWrapper}>
        <div css={S.CardMachineSection}>
          <section css={S.CardMachineContent}>
            <ContentInformation
              titleSection={titleSection}
              titleContent={content.title}
              subtitleContent={content.subTitle}
            />
            <div css={S.CardMachineButtonContainer}>
              <Button onClick={handleRedirect}>{button.text}</Button>
            </div>
          </section>
          <figure css={S.CardMachineImageContent}>
            <Img src={image.path} alt={image.alt} />
          </figure>
        </div>
      </div>
    </div>
  )
}

export default SectionCardMachine
