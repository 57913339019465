import { isMobile } from "@mkt/utils/getDevice"

const useHero = () => {
  const scrollDown = () => {
    const headerHeight = document?.querySelector("#header")?.offsetHeight
    
    const scrollMeasure = isMobile() ? 568 : 654

    scroll({
      top: scrollMeasure + headerHeight,
      behavior: "smooth",
    })
  }

  return { scrollDown }
}

export default useHero
