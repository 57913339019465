import Button from "@mkt/ui/components/Azulis/Button"
import Img from "@mkt/ui/components/common/Img"
import { dataAccount } from "../../data"
import useLanding from "../../hooks"
import ContentInformation from "../ContentInformation"
import * as S from "./styles"

const SectionAccount = () => {
  const { titleSection, image, content, button } = dataAccount
  const { handleRedirect } = useLanding({ button })

  return (
    <div css={S.AccountWrapper}>
      <div css={S.AccountContainer}>
        <figure css={S.AccountContainerImage}>
          <Img src={image.path} alt={image.alt} />
        </figure>
        <section css={S.AccountContentInfo}>
          <ContentInformation
            titleSection={titleSection}
            titleContent={content.title}
            subtitleContent={content.subTitle}
            dashContent={true}
          />
          <div>
            <Button onClick={handleRedirect}>{button.text}</Button>
          </div>
        </section>
      </div>
    </div>
  )
}

export default SectionAccount
