import Text from "@mkt/ui/components/Azulis/Text"

import DashTitle from "../DashTitle"
import * as S from "./styles"

const ContentInformation = ({
  titleSection,
  titleContent,
  color = "blue",
  subtitleContent,
  dashContent = false,
}) => {
  const defaultColor = "blue"
  const isDefaultColor = color === defaultColor

  const classesColorText = {
    titleContent: isDefaultColor ? S.ContentTitleBlue : S.ContentTitleWhite,
    titleSectionAndSubtitle: isDefaultColor
      ? S.ContentTextBlack
      : S.ContentTitleWhite,
  }

  return (
    <>
      {dashContent && <DashTitle />}
      <Text
        as="h4"
        variant={[
          "Text16",
          "SemiBold",
          S.TitleSection,
          classesColorText.titleSectionAndSubtitle,
        ]}
      >
        {titleSection}
      </Text>
      <Text
        as="p"
        variant={["HeadingTwo", S.ContentTitle, classesColorText.titleContent]}
      >
        <span
          dangerouslySetInnerHTML={{
            __html: titleContent,
          }}
        />
      </Text>
      <Text
        as="p"
        variant={[
          "Text16",
          S.ContentSubTitle,
          classesColorText.titleSectionAndSubtitle,
        ]}
      >
        {subtitleContent}
      </Text>
    </>
  )
}

export default ContentInformation
