import { css } from "@emotion/react"
import { colors } from "@mkt/azulis/src/styles/Tokens"

export const TitleSection = css`
  margin-bottom: 33px;
  z-index: 1;
  text-transform: uppercase;
`

export const ContentTitle = css`
  margin-bottom: 24px;

  .text-green {
    color: ${colors.green.default};
  }
`

export const ContentTitleBlue = css`
  color: ${colors.blue.default};
`

export const ContentTextBlack = css`
  color: ${colors.black};
`

export const ContentTitleWhite = css`
  color: ${colors.white};
`

export const ContentSubTitle = css`
  margin-bottom: 56px;
`
