import Button from "@mkt/ui/components/Azulis/Button"
import Img from "@mkt/ui/components/common/Img"
import { dataLoans } from "../../data"
import useLanding from "../../hooks"
import ContentInformation from "../ContentInformation"
import * as S from "./styles"

const SectionLoans = () => {
  const { titleSection, image, content, button } = dataLoans
  const { handleRedirect } = useLanding({ button })

  return (
    <div css={S.SectionLoansWrapper}>
      <div css={S.SectionLoansContainer}>
        <figure css={S.SectionLoansImageContent}>
          <Img src={image.path} alt={image.alt} />
        </figure>
        <section css={S.SectionLoansInfo}>
          <ContentInformation
            titleSection={titleSection}
            titleContent={content.title}
            subtitleContent={content.subTitle}
            color={"white"}
          />
          <div css={S.SectionLoansButtonWrapper}>
            <Button onClick={handleRedirect} color="inverted">
              {button.text}
            </Button>
          </div>
        </section>
      </div>
    </div>
  )
}

export default SectionLoans
