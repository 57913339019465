export const dataHero = {
  title:
    "Te ajudamos a encontrar os melhores produtos e serviços para o seu negócio.",
  subtitle: "Comece encontrando sua maquininha de cartão hoje.",
  image: {
    path: "https://assets.iq.com.br/f/114280/1248x600/b3e217a983/hero-landing-image-azulis.jpeg",
    alt: "Imagem de uma mulher sorrindo em frente ao computador",
  },
}

export const dataCardMachine = {
  titleSection: "MAQUININHAS",
  image: {
    path: "https://assets.iq.com.br/f/114280/628x447/ff69879143/maquininhas-landing.png",
    alt: "Três máquinhas de cartão, vermelha (SuperGet), amarela (pagSeguro) e azul (Mercado pago)",
  },
  content: {
    title:
      "Existem mais de 50 tipos de maquininhas de cartão. A certa para o <span class='text-green'>seu negócio</span> está na Azulis!",
    subTitle: "Aproveite as promoções exclusivas por tempo limitado.",
  },
  button: {
    redirect: "/maquininha-de-cartao",
    text: "Encontre agora",
  },
}

export const dataLoans = {
  titleSection: "EMPRÉSTIMOS",
  image: {
    path: "https://assets.iq.com.br/f/114280/520x564/6f0c8607ef/landing-home-emprestimos.png",
    alt: "Celular com a página da azulis listando os empréstimos, com um cifrão em segundo plano.",
  },
  content: {
    title:
      "Quer <span class='text-green'>expandir</span> seu negócio, mas precisa de dinheiro?",
    subTitle:
      "Nós selecionamos as melhores opções de empréstimo para a sua empresa.",
  },
  button: {
    redirect: "/emprestimo",
    text: "Encontre agora",
  },
}

export const dataCards = {
  titleSection: "CARTÕES DE CRÉDITO",
  image: {
    path: "https://assets.iq.com.br/f/114280/397x551/52e45b45e6/landingpage-credit-cards.png",
    alt: "Três cartões, pagseguro (branco), Ipiranga (preto) e Passaí (azul).",
  },
  content: {
    title:
      "Mais do que cartão de crédito, aqui você encontra as <span class='text-green'>melhores vantagens</span>.",
    subTitle:
      "Como um cartão de crédito pode ajudar no seu negócio? Confira a nossa lista de cartões e descubra qual o cartão ideal para você.",
  },
  button: {
    redirect: "/cartao-de-credito",
    text: "Encontre agora",
  },
}

export const dataAccount = {
  titleSection: "CONTAS",
  image: {
    path: "https://assets.iq.com.br/f/114280/488x564/0025cc760f/landing-contas-digitais.png",
    alt: "Celular com as contas digitais aberta na tela, Superdigital, banco Inter, Itaú, Santander, Neon, Next, PagBank, Bradesco e BB.",
  },
  content: {
    title:
      "Melhores taxas e mais <span class='text-green'>controle financeiro</span>.",
    subTitle:
      "Aqui você encontra a melhor opção para gerenciar o seu dinheiro com contas digitais exclusivas para MEI, Pessoa Jurídica e Pessoa Física.",
  },
  button: {
    redirect: "/conta-digital",
    text: "Encontre agora",
  },
}

export const dataContent = {
  titleSection: "Conteúdo",
  descriptionSection: "Artigos e vídeos para ajudar seu negócio a decolar!",
  buttons: [
    {
      text: "Inscreva-se no Youtube",
      redirect: "https://www.youtube.com/azulisbrasil/?sub_confirmation=1",
      type: "primary",
    },
    {
      text: "Conteúdo gratuito",
      redirect: "https://www.azulis.com.br/vida-de-dono/",
      type: "secondary",
    },
  ],
  imageMain: {
    path: "https://assets.iq.com.br/f/114280/385x220/12ca274c38/section-content-image.png",
    alt: "Pessoa de social digitando em um notebook.",
  },
  articles: [
    {
      image: {
        path: "https://assets.iq.com.br/f/114280/330x168/20b6fbb16d/articles-landing-home-cnpj-mei.jpeg",
        alt: "Duas pessoas rindo olhando um notebook.",
      },
      title: "Tudo que você precisa saber para abrir um CNPJ MEI.",
      redirect: "https://www.azulis.com.br/guia-mei/",
      editor: "Emily Moura",
      date: "26 de fevereiro de 2019",
    },
    {
      image: {
        path: "https://assets.iq.com.br/f/114280/330x168/1a03091372/articles-landing-home-maquina-cartao.jpeg",
        alt: "Homem sorrindo com uma maquinha de cartão na mão.",
      },
      title:
        "Máquina de cartão. Tudo que você precisa saber antes de escolher a melhor.",
      redirect:
        "https://www.azulis.com.br/artigo/maquina-de-cartao-tudo-o-que-voce-precisa-saber-antes-de-escolher-a-melhor",
      editor: "Redação Azulis",
      date: "26 de dezembro de 2019",
    },
    {
      image: {
        path: "https://assets.iq.com.br/f/114280/330x168/b01329600e/articles-landing-home-seu-negocio.jpeg",
        alt: "Uma pessoa esta passando o Cartão em uma máquininha que está na mão de outra pessoa.",
      },
      title: "O que é CNAE, como consultar e diferenças para o MEI",
      redirect: "https://www.azulis.com.br/artigo/cnae-mei",
      editor: "Redação Azulis",
      date: "18 de fevereiro de 2019",
    },
  ],
}

export const dataAbout = {
  contentAbout: [
    {
      subtitle: "Nós somos a loja online do empreendedor de sucesso",
      desc: "Nosso compromisso é oferecer as melhores opções de maquininhas de cartão, empréstimos, cartões e contas digitais para o dono ou dona de negócio que quer ver seu negócio decolar.",
    },

    {
      subtitle: "Utilizamos tecnologia de ponta e parcerias estratégicas",
      desc: "para entregar ao cliente da Azulis formulários totalmente online e promoções exclusivas. Por isso, antes de escolher, procure na Azulis!",
      hightlightText: "E não para por aí!",
    },
    {
      subtitle: "Encontre dicas valiosas no portal Vida de Dono",
      desc: "e curiosidades do mundo do empreendedorismo.",
    },

    {
      subtitle: "O canal do YouTube da Azulis",
      desc: "também está recheado de vídeos que ajudam o empreendedor a vender mais, assim como lives com as últimas novidades e tendências que o dono de negócio precisa acompanhar.",
    },
  ],
}
