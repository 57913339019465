import { navigate } from "gatsby"

const useLanding = ({ button }) => {
  const { redirect } = button

  const handleRedirect = () => {
    navigate(redirect)
  }

  return { handleRedirect }
}

export default useLanding
