import { dataAbout } from "../../data"
import * as S from "./styles"

const SectionAbout = () => {
  return (
    <section css={S.SectionAbout}>
      <div css={S.SectionAboutWrapper}>
        <div css={S.SectionAboutContent}>
          <div css={S.BackgroundSlash}>
            <div className="slash"></div>
          </div>
          <h2 css={S.ContentTitle}>Quem somos</h2>

          <div css={S.ContentDescription}>
            {dataAbout.contentAbout.map((item, index) => {
              const { subtitle, desc, hightlightText } = item
              return (
                <div
                  css={S.ContentDescriptionBox}
                  className={`box-${index}`}
                  key={`box-section-about-${index}`}
                >
                  <p css={S.BoxSubtitle}>{subtitle}</p>
                  <p css={S.BoxDesc}>{desc}</p>
                  {hightlightText && (
                    <p css={S.BoxHighlight}>{hightlightText}</p>
                  )}
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </section>
  )
}

export default SectionAbout
