import Text from "@mkt/ui/components/Azulis/Text"
import Button from "@mkt/ui/components/Azulis/Button"
import Img from "@mkt/ui/components/common/Img"
import { dataContent } from "../../data"
import * as S from "./styles"

const ContentSection = () => {
  const { titleSection, imageMain, descriptionSection, articles, buttons } =
    dataContent

  return (
    <div css={S.ContentWrapper}>
      <div css={S.ContentContainer}>
        <div css={S.ContentHero}>
          <figure css={S.ContentImage}>
            <Img src={imageMain.path} alt={imageMain.alt} />
          </figure>
          <section css={S.ContentImageLegend}>
            <Text
              as="h2"
              variant={["HeadingTwo", "SemiBold", S.ContentTitleLegend]}
            >
              {titleSection}
            </Text>
            <Text as="p" variant={["Text16", S.ContentDescriptionLegend]}>
              {descriptionSection}
            </Text>
            <div css={S.ContentButtons}>
              {buttons?.map((button, index) => {
                const { type, redirect, text } = button
                return (
                  <Button
                    type={type}
                    onClick={() => {
                      window.location.href = redirect
                    }}
                    key={`#button-content-section-${index}`}
                  >
                    {text}
                  </Button>
                )
              })}
            </div>
          </section>
        </div>
        <div css={S.ContentArticles}>
          {articles?.map((article, index) => {
            const { image, date, editor, redirect, title } = article
            return (
              <article
                css={S.ContentArticleItem}
                key={`#article-content-section-0-${index}`}
              >
                <a href={redirect}>
                  <figure css={S.ContentArticleFigure}>
                    <Img src={image.path} alt={image.alt} />
                  </figure>
                  <section css={S.ArticleContent}>
                    <Text
                      as="h4"
                      variant={["Text16", "SemiBold", S.ArticleTitle]}
                    >
                      {title}
                    </Text>
                    <div css={S.ArticlePublish}>
                      <Text as="p" variant={["Text14", S.ArticlePublishText]}>
                        Por <Text as="span">{editor}</Text>
                      </Text>
                      <Text
                        as="p"
                        variant={[
                          "Text14",
                          S.ArticlePublishText,
                          S.ArticlePublishDate,
                        ]}
                      >
                        {date}
                      </Text>
                    </div>
                  </section>
                </a>
              </article>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default ContentSection
