import isClient from "./isClient"

/* TODO = {
	Get breakpoints reference by global dynamic config
} */

export const isDesktop = () => {
  if (!isClient()) return

  return !!window.matchMedia("screen and (min-width:1024px)").matches
}

export const isTablet = () => {
  if (!isClient()) return

  return !!window.matchMedia(
    "screen and (min-width:768px) and (max-width: 1024px)"
  ).matches
}

export const isMobile = () => {
  if (!isClient()) return
  return !!window.matchMedia("screen and (max-width:767px)").matches
}

export const isMobileDevice = isMobile()

export const isNoDesktop = () => {
  if (!isClient()) return

  return !!window.matchMedia("screen and (max-width:1023px)").matches
}
