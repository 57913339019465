import Button from "@mkt/ui/components/Azulis/Button"
import Img from "@mkt/ui/components/common/Img"
import { dataCards } from "../../data"
import useLanding from "../../hooks"
import ContentInformation from "../ContentInformation"
import * as S from "./styles"

const SectionCard = () => {
  const { titleSection, image, content, button } = dataCards
  const { handleRedirect } = useLanding({ button })

  return (
    <div css={S.CardWrapper}>
      <div css={S.CardContainer}>
        <section css={S.CardContentInfo}>
          <ContentInformation
            titleSection={titleSection}
            titleContent={content.title}
            subtitleContent={content.subTitle}
          />
          <div css={S.CardButtonContainer}>
            <Button onClick={handleRedirect}>{button.text}</Button>
          </div>
        </section>
        <figure css={S.CardContainerImage}>
          <Img src={image.path} alt={image.alt} />
        </figure>
      </div>
    </div>
  )
}

export default SectionCard
