import { css } from "@emotion/react"
import { colors } from "@mkt/azulis/src/styles/Tokens"

export const DashContent = css`
  width: 72px;
  height: 210px;
  position: absolute;
  margin-left: -24px;
  top: -50px;
  z-index: 0;

  &:before {
    content: "";
    width: 30px;
    height: 100%;
    display: block;
    position: absolute;
    transform: skew(-26deg);
    background: ${colors.gray.lightest_f5};
    left: 0;
  }
`
